import styled from "styled-components"
import { Table as BaseTable } from "../../styles/styled"

let S = {}
S.Container = styled.div`
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 25px;
  margin-bottom: 25px;
`

S.H2 = styled.h2`
  margin-top: 15px;
  margin-bottom: 2.5px;
`

S.Table = styled(BaseTable)`
  margin-top: 25px;

  &.order th {
    width: 33%;
  }

  &.order th:nth-child(2) {
    width: 34%;
  }

  & div.details {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;

    @media only screen and (max-width: 649px) {
      flex-direction: column;
    }

    & > div {
      @media only screen and (max-width: 649px) {
        margin-bottom: 15px;
      }
    }

    & > div:last-child {
      @media only screen and (max-width: 649px) {
        margin-bottom: 0;
      }
    }

    & div > p:first-child {
      margin-top: 2.5px;
      margin-bottom: 7.5px;
      font-weight: bold;
    }

    & div > p:last-child {
      margin-bottom: 5px;
    }
  }

  & .price {
    width: 15%;
  }

  & .item {
    padding: 7.5px;
    display: flex;
    flex-direction: column;

    & > p {
      margin-bottom: 10px;
    }

    & > a {
      display: flex;
      align-items: center;
      font-weight: bold;

      & > span {
        padding-left: 5px;
      }
    }
  }

  & .total th,
  & .total td {
    text-transform: uppercase;
    font-size: 1.05rem;
    padding-top: 15px;
    padding-bottom: 15px;
  }
`
export default S
