import React from "react"
import { fmtCurrency } from "../../utils"
import Alert from "../../components/Alert"
import S from "./styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLink } from "@fortawesome/pro-light-svg-icons"

const Content = ({ receipt }) => {
  return (
    <S.Container>
      <Alert
        data={{
          type: "success",
          text: "Your order has been submitted, thank you!",
        }}
      />

      <S.H2>Order Receipt</S.H2>

      <p style={{ fontSize: "0.95rem" }}>
        A copy of this order has been emailed to{" "}
        <strong>{receipt.email}</strong>, please remember to check your spam
        folder if not received in a few minutes.
      </p>

      <S.Table className="order">
        <thead>
          <tr>
            <th>Name</th>
            <th>Order Number</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="center">{receipt.name}</td>
            <td className="center">{receipt.id}</td>
            <td className="center">{receipt.date}</td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td colSpan="3">
              <div className="details">
                <div>
                  <p>Contact Info</p>
                  <p>
                    {receipt.email}
                    <br />
                    {receipt.phone}
                  </p>
                </div>

                <div>
                  <p>Firm</p>
                  <p>
                    {receipt.firm}
                    <br />
                    {receipt.memberNumber
                      ? `PBCBA Member (#${receipt.memberNumber})`
                      : "Non Member"}
                  </p>
                </div>

                {receipt.totals.shipping > 0 && (
                  <div>
                    <p>Shipping Address:</p>
                    <p>
                      {receipt.address1}
                      <br />
                      {receipt.address2}
                    </p>
                  </div>
                )}
              </div>
            </td>
          </tr>
        </tfoot>
      </S.Table>

      <S.Table>
        <thead>
          <tr>
            <th className="left">Course</th>
            <th>Format</th>
            <th className="price">Amount</th>
          </tr>
        </thead>

        <tbody>
          {receipt.items.map((item, index) => {
            return (
              <tr key={item.id}>
                <td>
                  <div className="item">
                    <p>{item.name}</p>

                    <a
                      href={item.purchasedUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faExternalLink} size="md" />
                      <span>View / Download Course Details & Assets</span>
                    </a>
                  </div>
                </td>
                <td className="center">{item.format}</td>
                <td className="right">{fmtCurrency(item.amt / 100)}</td>
              </tr>
            )
          })}
        </tbody>

        <tfoot>
          {(receipt.totals.discount > 0 || receipt.totals.shipping > 0) && (
            <tr>
              <th className="right" colSpan="2">
                Subtotal
              </th>
              <td className="right">
                {fmtCurrency(receipt.totals.subtotal / 100)}
              </td>
            </tr>
          )}

          {receipt.totals.discount > 0 && (
            <tr>
              <th className="right" colSpan="2">
                Discount
              </th>
              <td className="right">
                <span style={{ color: "var(--error)" }}>
                  ({fmtCurrency(receipt.totals.discount / 100)})
                </span>
              </td>
            </tr>
          )}

          {receipt.totals.shipping > 0 && (
            <tr>
              <th className="right" colSpan="2">
                <p style={{ marginBottom: "2.5px" }}>Shipping</p>

                <small
                  style={{
                    display: "inline-block",
                    fontWeight: "normal",
                    lineHeight: "18px",
                  }}
                >
                  Please allow three (3) business days preparation time if your
                  order includes CDs or DVDs.
                </small>
              </th>
              <td className="right">
                {fmtCurrency(receipt.totals.shipping / 100)}
              </td>
            </tr>
          )}

          <tr>
            <th className="right" colSpan="2">
              Total
            </th>
            <td className="right">{fmtCurrency(receipt.totals.total / 100)}</td>
          </tr>

          <tr className="total">
            <th className="right" colSpan="2">
              {receipt.totals.paid}
            </th>
            <td className="right">{`(${fmtCurrency(
              receipt.totals.total / 100
            )})`}</td>
          </tr>
        </tfoot>
      </S.Table>
    </S.Container>
  )
}

export default Content
